import * as React from 'react'
import { sanityConfig } from '../utils'
import {css} from "@emotion/core";
import { Button } from './Button'
import { FluidArgs, getFluidGatsbyImage } from './getGatsbyImageProps'
import { useCallback, useState } from 'react'
import {Lightbox, Carousel, ItemRenderer, CloseButton, NextButton, PrevButton} from '@elsdoerfer/lightbox-framework';
import ScrollLock from 'react-scrolllock'

import '@elsdoerfer/lightbox-framework/base.css';
import "@elsdoerfer/lightbox-framework/defaultStyle.css";
import './ContactForImageModal.css';
import { LoadedImage } from './GalleryGrid'


const contactButtonStyle = css`
  strong {
    font-size: 1.4em;
    text-decoration: none;
  }
  p {
    margin: 0;
  }
`;


// Later, have a global modal renderer which reacts to a url hash
// Also, if every one of those picture has a description, it might be worth it
// to give every one of them their own page as well (similar to gatsbygram),
// and, by the tags, link to the right pages and the right content.
export function ContactForImageModal(props: {
  isOpen: boolean,
  onRequestClose: any,
  images?: LoadedImage[],
  initialIndex?: number
}) {
  const {isOpen, onRequestClose, images, initialIndex} = props;
  if (!images || !images.length) {
    return null;
  }

  return <Lightbox
    isOpen={isOpen}
    onBackdropClick={onRequestClose}
  >
    {() => {
      return <Carousel
        items={images}
        defaultIndex={initialIndex || 0}
      >
        <ScrollLock isActive={true} />

        <ItemRenderer render={(item: LoadedImage) => {
          if (!item) {
            return;
          }
          return <>
            <View
              key={item.image.asset.url}
              data={item.image}
            />
            <CloseButton
              onClick={onRequestClose}
            />
            <NextButton />
            <PrevButton />
            <Footer
              interactionIsIdle={false}
              currentView={item}
            />
          </>
        }} />
      </Carousel>
    }}
  </Lightbox>
}


/**
 * Custom footer for react-images.
 */
export function Footer(props: {
  interactionIsIdle: boolean,
  currentView: LoadedImage
}) {
  const imageObject: any = props.currentView;

  return <div css={css`
      color: rgba(255, 255, 255, 0.9);
      background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.33));
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 20px;
      opacity: ${props.interactionIsIdle ? 0 : 1};
      transition: opacity 300ms, transform 300ms;
  `}>
    <div css={css`
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      
      > :first-child {
        margin-right: 20px;
        
        h3 {
          margin: 0;
          font-size: 26px;
        }
      }
    `}>

      <div>
        <h3>Interesse an diesem Werkstück?</h3>
      </div>
      {(imageObject && imageObject.onlineShopId) ?
        <Button css={contactButtonStyle} href={`https://shop.blumen-elsdoerfer.de/products/${imageObject.onlineShopId}`}>
          <strong>Online bestellen</strong>
          <p>Wir liefern im Raum München</p>
        </Button>
        : null}

      <Button css={contactButtonStyle} to={"/kontakt"}>
        <strong>Kontakt</strong>
        <p>Wir beraten sie gerne.</p>
      </Button>

    </div>
  </div>
}


/**
 * react-images by itself shows images using <img />. We wanted to use gatsby-image to get the nice loading
 * effect it has, which is why we created this class.
 *
 * But the truth is, it is not a appropriate. First, neither its fixed mode nor it's fluid mode do exactly
 * what we want. Second, it outputs a whole bunch of junk that make it really difficult for us to figure
 * out where we have to modify the markup to get the image to properly scale, for both portrait and landscape
 * images.
 *
 * So we are recreating the mechanism ourselves, including the loading effect.
 */
export function View(props: {
  data: {
    asset: any,
  }
}) {
  const imageObject = props.data;

  // maxWidth has the effect of causing multiple versions of the image to be in the srcset, with 3x maxWidth
  // being the limit. There is no src set selection by height (https://github.com/whatwg/html/issues/2973).
  // This is how getFluidGatsbyImage() image works.
  //
  // But we want to limit both the width and the height. The Sanity max-h and max-w attributes alone do
  // not have this effect. Either fit=max + fit=clip must be used.
  //
  // So the effect is an image that fits into a 1400px box. Note however that the helper function will also
  // create versions of the image with a scale up to 3x for retina support.
  const sanityOpts: FluidArgs = {maxWidth: 1400, maxHeight: 1400, fit: 'clip', cdnArgs: {
      kind: 'imgix',
      "mark-scale": 100,
      auto: 'compress,enhance,format',
      // TODO: Use the actual watermark from the company profile
      mark: '7dc0a6da8ba7395d0158d2aab00e89708c58f580-5456x384.png'
  }};
  const fluidImageData = getFluidGatsbyImage(imageObject.asset, sanityOpts);

  // So this uses the full space, and displays the content centered. If the image is too wide, it will
  // fit into the space. However, if it is to high, it will stretch beyond it's container, being not fully
  // visible. To fix the latter, we simply set max-height: 100% to the image.
  return <div css={css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;    
    height: 100vh;
    width: 100vw;   
  `}>
    <MyImg
      fluid={fluidImageData}
      dimensions={imageObject.asset.metadata.dimensions}
    />
  </div>
}


/**
 * A gatsby-image like loading effect, but simpler. The point is that we
 * can control the HTML elements generated better, so we can get the image
 * full screen scaling right, which we failed to due with gatsby-image.
 */
export function MyImg(props: {
  fluid: any,
  dimensions: any,
  onClick?: any,
  alt?: string,
  className?: string,
}) {
  const imageVariants = groupByMedia([].concat(props.fluid))
  const image = imageVariants[0];

  const [isLoaded, setLoaded] = useState(false);
  const handleImageLoaded = useCallback(() => {
    setLoaded(true);
  }, [setLoaded]);

  return <>
    {/*
        Until loaded, show the base64 placeholder. We set the actual image
        width and height, we limit it to the 100% if too big, and we force
        it to keep it's aspect ratio.

        This has the side-effect of requiring the full screen in many cases,
        and not letting clicks go through to the background element which
        closes the popup, but since this is only during load, we accept it for now.
     */}
    {!isLoaded ? <img
      style={{maxHeight: '100%', maxWidth: '100%', objectFit: 'contain'}}
      width={props.dimensions.width}
      height={props.dimensions.height}
      src={image.base64}
    /> : null}

    {/*
      - Hide until loaded.
      - Limit max-height, which together with the parent flexbox ensures that
        the image behaves similar to object-fit, but without taking the
        background space.
    */}
    <picture
      style={{maxHeight: '100%', display: isLoaded ? undefined : 'none'}}
      onClick={props.onClick}
      className={props.className}
    >
      {generateImageSources(imageVariants)}
      <img
        className={"galleryImage"}
        style={{maxHeight: '100%'}}
        src={image.src}
        srcSet={image.srcSet}
        onLoad={handleImageLoaded}
        alt={props.alt}
      />
    </picture>
  </>;
}


/**
 * From gatsby-image.
 */
function generateImageSources(imageVariants, sizes?: string) {
  return imageVariants.map(({ src, srcSet, srcSetWebp, media, sizes }) => (
    <React.Fragment key={src}>
      {srcSetWebp && (
        <source
          type="image/webp"
          media={media}
          srcSet={srcSetWebp}
        />
      )}
      <source media={media} srcSet={srcSet} sizes={sizes} />
    </React.Fragment>
  ))
}

// From gatsy-image.
// Return an array ordered by elements having a media prop, does not use
// native sort, as a stable sort is not guaranteed by all browsers/versions
function groupByMedia(imageVariants) {
  const withMedia = []
  const without = []
  imageVariants.forEach(variant =>
    (variant.media ? withMedia : without).push(variant)
  )

  if (without.length > 1 && process.env.NODE_ENV !== `production`) {
    console.warn(
      `We've found ${without.length} sources without a media property. They might be ignored by the browser, see: https://www.gatsbyjs.org/packages/gatsby-image/#art-directing-multiple-images`
    )
  }

  return [...withMedia, ...without]
}