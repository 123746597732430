import { KeypointBox } from './RefToKeypointBox'
import * as React from 'react'
import { css } from '@emotion/core'
import ContentMargins from './ContentMargins';


const style = css`
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
`;


/**
 * Multiple KeypointBoxes in a row.
 */
export function KeypointBoxes(props: {
  links: {
    _id: string,
    title: string,
    shortText: string,
    slug: {
      current: string
    }
  }[]
}) {
  const {links} = props;
  const content = links.map(link => {
    return <KeypointBox keyPoint={link} key={link._id} />
  });

  return <ContentMargins>
    <div css={style}>
      {content}
    </div>
  </ContentMargins>
}