import ContentMargins from './ContentMargins'
import React from 'react'
import styled from "@emotion/styled"
import {graphql} from "gatsby"
import { getFluidGatsbyImage } from './getGatsbyImageProps'
import { sanityConfig } from '../utils'


const CoverContainer = styled.div`
  margin-top: 0px;
  min-height: 400px; 
  display: flex;
  flex-direction: row;

  background: #9B1030;
  color: white;
  padding: 4em;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.25),
    rgba(0, 0, 0, 0.45)
  ), var(--cover-url);
  background-size: cover;
  background-position: center center;

  .quote {
      max-width: 900px;
      width: 100%;
      text-align: center;
      color: #fafafa;
      
      .title {
        font-size: 45px;        
        padding: 5px;
        text-shadow: 1px 1px 7px black;
      }
      
      .subtitle {        
        font-size: 25px;        
        padding: 5px;
        text-shadow: 1px 1px 7px black;
      }
  }
`;


export const HeroImage = ({title, subtitle, image, buttons}) => {
  const style = {
    '--cover-url': `url(${image})`
  };

  // This removes the linear-gradient that is applied in CSS
  if (!image) {
    style.backgroundImage = "none";
  }

  return <ContentMargins>
    <CoverContainer style={style}>
      <div className="quote">
        <div className="title">
          {title}
        </div>
        {subtitle ? <div className="subtitle">
          {subtitle}
        </div> : null}
        {buttons}
      </div>
    </CoverContainer>
  </ContentMargins>;
}


export const HeroImageFromQuery = ({ hero, buttons }) => {
  const {title, subtitle, image} = hero;

  let backgroundImage = undefined;
  if (image) {
    // A good default size is 1180 x 400. FluidGatsby will give us crops for this size
    // and possibly other kinds of widths, hopefully cropped for a height of 400. At some
    // point the width becoming less might mean the height becomes more, in which case we'll
    // have to zoom in browser side.
    const resolved = getFluidGatsbyImage(image.asset, {maxWidth: 1180, maxHeight: 400, cdnArgs: {kind: 'sanity', loc: sanityConfig}});
    backgroundImage = resolved?.src;
  }


  return <HeroImage
    title={title}
    subtitle={subtitle}
    image={backgroundImage}
    buttons={buttons}
  />;
}


export const HeroImageFragment = graphql`
  fragment HeroImageFragment on SanityHeroImage {          
    title,
    subtitle,
    image {
      asset {
        _id,
        url,
        assetId,
        extension,
        metadata {
          dimensions {
            width,
            height,
            aspectRatio
          },
          lqip
        }
      }
    }
  }    
`