import * as React from 'react';
import styled from "@emotion/styled";
import {graphql} from "gatsby"
import { Link } from './Link'

export const TEL_LINK_ACTION = 'tel-link';
export const CONTACT_ACTION = 'contact';
export const GOTO_SHOP_ACTION = 'goto-onlineshop';


export const CallToActionButton = (props) => {
  const {label, subtitle, action} = props;

  if (action === TEL_LINK_ACTION) {
    return <DefaultButton label={label} subtitle={subtitle} />
  }

  if (action === CONTACT_ACTION) {
    return <ContactFormButton label={label}  subtitle={subtitle} />
  }

  if (action === GOTO_SHOP_ACTION) {
    return <GoToShopButton label={label} subtitle={subtitle} />
  }

  console.error('Unknown call to action type "' + action + '"');
  return null;
}

export const CallToActionButtonFragment = graphql`
  fragment CallToActionButtonFragment on SanityCallToAction {          
    label,
    action,
    subtitle 
  }
`;


function DefaultButton(props: any) {
  const {label, subtitle, ...rest} = props;
  return <Button {...rest}>
    <div className={"label"}>{props.label}</div>
    <div>{props.subtitle}</div>
  </Button>
}

function ContactFormButton(props) {
  return <DefaultButton to={"/kontakt"} {...props} />
}

function GoToShopButton(props) {
  return <DefaultButton href={`https://shop.blumen-elsdoerfer.de/`} {...props} />
}


export const Buttons = styled.div`
  text-align: center;
  margin-top: 40px;
  
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(100px, 250px));
  grid-column-gap: 20px;
  justify-content: center;
`;


export const Button = styled(Link)`
  --color: #FFD166;
  /* #26547C, #26547C */
  
  display: inline-block;
  border: 4px solid var(--color);
  padding: .4em 1.2em;
  background: var(--color);
  /* box-shadow: 1px 1px 3px 3px #00000063; */
  box-shadow: 2px 2px 3px 1px #00000063;
  
  border-radius: 3px;
  font-size: 15px;
  cursor: pointer;  
  
  color: black;
  text-decoration: none;
  font-weight: normal;  
  
  .label {
    font-size: 1.4em;
    font-weight: bold;
  }
  
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  transition: all .3s ease-in-out;
  &:hover {
    text-decoration: none;
    --color: #FFC107;
  }
`;
