import * as React from 'react'
import { ContactForImageModal } from './ContactForImageModal'
import ContentMargins from './ContentMargins'
import { css } from '@emotion/core'
import { useWindowSize } from 'react-use'
import { useState } from 'react'
import { FluidArgs, getFluidGatsbyImage, ImgIxCdnArgs } from './getGatsbyImageProps'
import { useCallback } from 'react'


export type LoadedImage = {
  tags: string[],
  onlineShopId?: string,
  image: {
    asset: {
      url: string,
      assetId: string
      extension: string
      metadata: any
      _id: string
    },
    hotspot?: any,
    crop?: any
  }
};


/**
 * Shows images in a grid arrangement, opens a lightbox in click.
 */
export function GalleryGrid(props: {
  images: LoadedImage[]
}) {
  const [showingPopupForImage, setShowingPopupForImage] = useState<number|null>(null);
  const images = props.images || [];

  const handleOnPopupRequestClose = () => {
    setShowingPopupForImage(null);
  }

  const handleSelect = (index) => {
    setShowingPopupForImage(index);
  }

  if (!props.images) {
    return null;
  }

  const gallery = <GalleryThumbnails
    images={images}
    onSelect={handleSelect}
    css={css`
        margin-top: 40px;
        margin-bottom: 40px;  
      `}
  />;

  return <>
    {gallery}

    <ContactForImageModal
      isOpen={showingPopupForImage !== null}
      onRequestClose={handleOnPopupRequestClose}
      initialIndex={showingPopupForImage}
      images={images}
    />
  </>;
}


const galleryThumbnailsStyles = css`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));

  .item {
    min-height: 180px;
    width: 100%;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }   
  }
`;


function GalleryThumbnails(props: {
  images: LoadedImage[],
  onSelect: (idx: number) => void,
  className?: string
}) {
  const {width, height} = useWindowSize();

  let minImageSize = width / props.images.length;
  let useContentMargins = (minImageSize > 400);

   const content = <div css={galleryThumbnailsStyles}>
    {props.images.map((image, index) => {
      if (!image.image.asset) {
        return null;
      }

      if ((image as any).image.hotspot) {
        console.log((image as any).image.hotspot);
      }

      // Basically, the maximum image is 400px, so we crop it to that
      // width/height.
      //
      // Letting the browser pick the right size for this is a sort of
      // unsolved problem: https://github.com/rachelandrew/cssgrid-ama/issues/142
      let cdnArgs: ImgIxCdnArgs = {
        kind: 'imgix',
        auto: 'compress,format',
      };
      if (image.image.hotspot) {
        cdnArgs = {
          ...cdnArgs,
          "fp-x": image.image.hotspot.x,
          "fp-y": image.image.hotspot.y,
          "fp-z": 1,
          //"fp-debug": true,
          crop: "focalpoint"
        }
      }
      const fluidData = getFluidGatsbyImage(image.image.asset,
        {
          maxWidth: 400,
          maxHeight: 400,
          cdnArgs: cdnArgs
        } as FluidArgs);
      return <LoadableImgTag
        onClick={() => { props.onSelect(index) }}
        fluid={fluidData}
        dimensions={image.image.asset.metadata.dimensions}
        alt={"Gallerie-Bild"}
        key={index}
        className={"item"}
        // The way the grid math works out, as long as we have enough images to
        // fill one row, the size of one item will be between 180px and 200px,
        // so pick a good value in the middle.
        // If there are less images, we should manually detect this and
        // use calc() in this sizes query to set it properly.
        sizes={"200px"}
      />;
    })}
  </div>;

  if (useContentMargins) {
    return <ContentMargins className={props.className}>
      {content}
    </ContentMargins>
  } else {
    return <div className={props.className}>
      {content}
    </div>
  }
}


export function LoadableImgTag(props: {
  fluid: any,
  dimensions: any,
  onClick?: any,
  alt?: string,
  className?: string,
  sizes?: string
}) {
  const image = props.fluid;

  const [isLoaded, setLoaded] = useState(false);
  const handleImageLoaded = useCallback(() => {
    setLoaded(true);
  }, [setLoaded]);

  return <>
    {/*
        Until loaded, show the base64 placeholder. We set the actual image
        width and height, we limit it to the 100% if too big, and we force
        it to keep it's aspect ratio.

        This has the side-effect of requiring the full screen in many cases,
        and not letting clicks go through to the background element which
        closes the popup, but since this is only during load, we accept it for now.
     */}
    {!isLoaded ? <img
      style={{width: '100%', height: '100%'}}
      className={props.className}
      src={image.base64}
    /> : null}

    {/*
      - Hide until loaded.
      - Limit max-height, which together with the parent flexbox ensures that
        the image behaves similar to object-fit, but without taking the
        background space.
    */}
    <img
      style={{maxHeight: '100%', display: isLoaded ? undefined : 'none'}}
      onClick={props.onClick}
      className={props.className}
      src={image.src}
      srcSet={image.srcSet}
      sizes={props.sizes}
      onLoad={handleImageLoaded}
      alt={props.alt}
    />
  </>;
}


