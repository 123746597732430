import React from 'react'
import {css} from '@emotion/core';


const style = css`
  line-height: .8em;
  color: #9b1031;
  margin-top: 3.5em;
  
  span {
    font-size: .6em;
    color: gray;
    font-weight: 300;  
  }
`;

export function HeaderWithSubtitle(props) {
  const {title, subtitle, anchorId} = props;
  return <>
    <div id={anchorId} css={css`display: block; position: relative; top: -255px; visibility: hidden;`}></div>
    <h1 css={style}>{title} <span>{subtitle}</span></h1>
  </>
}