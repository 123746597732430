import React from 'react';
import {css} from "@emotion/core";


const quote = css`
  color: #9b1031;
  font-size: 1.2em;
  font-style: italic;
`;


export function Quote({text, author}) {
  return <blockquote css={quote}>
    {text}
  </blockquote>
}