import * as React from 'react'
import { css } from '@emotion/core'
import ContentMargins from './ContentMargins'


// Look at https://developers.google.com/web/updates/2017/09/sticky-headers
export function SectionMenu(props: {
  sections: {
    id: string,
    title: string
  }[]
}) {
  return <div css={css`
    margin: 40px 0;
    
    box-sizing: border-box;
    
    position: sticky;
    // Match the header + nav
    top: 99px;
          
    background: whitesmoke;
    
    border-bottom: 5px solid #9a0f31;
    // On mobile it is sometimes too wide
    overflow-x: auto;
       
    .content {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      justify-content: center;      
    }
      
    li {
      display: inline-block;  
      padding: 10px;
      margin: 5px;
      text-align: center;
      align-self: center;
    }
  `}>
    <ContentMargins className={"content"}>
      {props.sections.map((section, idx) => {
        return <li key={idx}>
          <a href={`#${section.id}`}>{section.title}</a>
        </li>
      })}
    </ContentMargins>
  </div>
}