import * as React from 'react'
import './FeatureList.css';
import ContentMargins from './ContentMargins';



type Props = {
  features: {
    title: string,
    explainer: string,
    icon: string,
  }[]
}

const FeatureList = ({ features }: Props) => (
  <ContentMargins><div
    className="FeatureList"
  >
    {features.map((feature, idx) => {
      return <div className="FeatureList-Feature" key={idx}>
        <div>
          {feature.icon ? <RenderSvgCode code={feature.icon}/> : null}
        </div>
        <div>         
            <span style={{fontWeight: 'bold'}}>{feature.title}</span>
            <em>{feature.explainer}</em>
        </div>            
      </div>
    })}
  </div></ContentMargins>
)

export default FeatureList;


/**
 * The point of this whole setup is quite questionable. Just put the SVG code
 * itself here, why don't you.
 */
export function RenderSvgCode(props: {code: string}) {
  const markup = {
    __html: props.code
  };

  return <div dangerouslySetInnerHTML={markup}>
  </div>
}