import * as React from 'react';
import BlockContent from "@sanity/block-content-to-react";
import getSerializers from '@sanity/block-content-to-hyperscript/lib/serializers';

import Layout from '../components/layout';
import FeatureList from '../components/FeatureList';
import {Buttons, CallToActionButton} from '../components/CallToActionButton';
import ContentMargins from '../components/ContentMargins';
import { HeroImageFromQuery } from '../components/HeroImage'
import { Typography } from '../components/Typography'
import { Quote } from '../components/Quote'
import { GalleryGrid } from '../components/GalleryGrid'
import { HeaderWithSubtitle } from '../components/HeaderWithSubtitle'
import { RefToKeypointBox } from '../components/RefToKeypointBox'
import { KeypointBoxes } from '../components/KeypointBoxes'
import { graphql } from 'gatsby'
import { CallToActionBlock } from '../components/CallToActionBlock'
import { SectionMenu } from '../components/SectionMenu'
import { generateSlug } from '../components/generateSlug'


const defaultSerializers = getSerializers(React.createElement).defaultSerializers;


const makeSerializers = (props: {
  blocks: any[],
  callToActionDefaultConfigs: any[]
}) => ({
  types: {
    block: (props) => {
      const DefaultBlockSerializer = defaultSerializers.types.block;
      return <ContentMargins>
        <Typography>
          <DefaultBlockSerializer {...props} />
        </Typography>
      </ContentMargins>
    },

    headerWithSubtitle: ({node}) => {
      const {title, subtitle} = node;
      return <ContentMargins>
        <Typography>
          <HeaderWithSubtitle title={title} subtitle={subtitle} anchorId={generateSlug(title)} />
        </Typography>
      </ContentMargins>
    },

    featureList: ({node}) => {
      const {features} = node;
      return <ContentMargins>
        <Typography>
          <FeatureList features={(features || []).map(feature => {
            return {
              icon: feature.iconSvgCode,
              title: feature.short,
              explainer: feature.long,
            }
          })}/>
        </Typography>
      </ContentMargins>
    },

    gallery: ({node}) => {
      const {resolved} = node;
      return <GalleryGrid
        images={resolved}
      />
    },

    quote: ({node}) => {
      const {author, text} = node;
      return <ContentMargins>
        <Typography>
          <Quote
            author={author}
            text={text}
          />
        </Typography>
      </ContentMargins>;
    },

    keyPointLink: ({node}) => {
      // Still uses our manual resolve code from gatsby-node. Could now use the resolveReferences() option.
      const {resolved} = node;
      if (!resolved) {
        return null;
      }
      return <RefToKeypointBox keyPoint={resolved} />
    },

    keyPointLinks: ({node}) => {
      const {links} = node;
      if (!links || !links.length) {
        return null;
      }
      return <KeypointBoxes links={links} />
    },

    callToActionBlock: ({node}) => {
      return <CallToActionBlock
        mode={node.style}
        config={node.config}
        pageConfigs={props.callToActionDefaultConfigs}
      />
    },

    inPageMenu: (node, options, children) => {
      const sections = props.blocks.filter(b => b._type === 'headerWithSubtitle').map(block => {
        return {
          title: block.title,
          id: generateSlug(block.title)
        }
      });

      return <SectionMenu
        sections={sections}
      />
    },
  }
});


const ProductPageTemplate = (props) => {
  const page = props.data.page;

  const buttons = page.callToActionButtons && page.callToActionButtons.length ? <Buttons>
    {
      (page.callToActionButtons || []).map((button, idx) => {
        return <CallToActionButton key={idx} {...button} />
      })
    }
  </Buttons> : null;

  const serializers = makeSerializers({
    blocks: page.text,
    callToActionDefaultConfigs: page.callToActionDefaultConfigs
  });

  return (
    <Layout
      pageTitle={page.title}
    >
      {page.hero ? <HeroImageFromQuery
        hero={page.hero}
        buttons={buttons}
      /> : null}

      {page.text ? <BlockContent
        blocks={page.text}
        serializers={serializers}
      /> : false}
    </Layout>
  )
}


export default ProductPageTemplate;


export const pageQuery = graphql`
  query ProductPageBySlug($id: String!) {
    page: sanityProductPage(id: { eq: $id }) {
      id,
      title,
      callToActionDefaultConfigs: _rawCallToActionDefaultConfigs,
      text: _rawText(resolveReferences: {maxDepth: 3}),
      hero {      
        ...HeroImageFragment
      },              
      callToActionButtons {
        ...CallToActionButtonFragment        
      }     
    }
  }
`
