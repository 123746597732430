import * as React from 'react'
import { css } from '@emotion/core'
import ContentMargins from './ContentMargins'
import { Button } from './Button'
import { graphql, useStaticQuery } from 'gatsby'


// As defined in Sanity
type RequestConsultationBlockConfig = {
  _type: 'requestConsultationConfig',
  prompt: string,
}
type ContactOrShopBlockConfig = {
  _type: 'contactOrShopConfig'
  promptContact: string,
  promptOnline: string,
}
export type Config = RequestConsultationBlockConfig|ContactOrShopBlockConfig;


/**
 * Supports multiple possible layouts.
 */
export function CallToActionBlock(props: {
  mode: 'requestConsultation'|'contactOrShop',
  config?: Config[],
  pageConfigs?: Config[]
}) {
  if (props.mode == 'requestConsultation') {
    return <RequestConsultationBlock
      prompt={findConfig('requestConsultationConfig', 'prompt', props.config, props.pageConfigs)}
      promptSubtitle={findConfig('requestConsultationConfig', 'promptSubtitle', props.config, props.pageConfigs)}
    />
  }
  if (props.mode == 'contactOrShop') {
    return <ContactOrShopBlock
      promptContact={findConfig('contactOrShopConfig', 'promptContact', props.config, props.pageConfigs)}
      promptOnline={findConfig('contactOrShopConfig', 'promptOnline', props.config, props.pageConfigs)}
    />
  }
  return null;
}


function findConfig(id: string, valueKey: string, ...opts: Config[][]): string|undefined {
  for (const list of opts) {
    if (!list) { continue; }
    for (const cfg of list) {
      if (cfg._type === id) {
        if (cfg[valueKey]) {
          return cfg[valueKey];
        }
      }
    }
  }
}

export function RequestConsultationBlock(props: {
  prompt: string,
  promptSubtitle: string,
}) {
  const phoneNumber = usePhoneNumber();

  return  <div css={css`    
    background: #ececec;
    margin: 40px 0;
    
    .content {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      padding: 2.5em 1em;
      
      // TO MAKE IT AN INLINE BOX
      // border: 1px solid silver;
      // border-radius: 4px;
      background: #ececec;
    }
    
    .left {
      text-align: center;
    }
    
    h2 {
      font-size: 30px;
      margin: 0;
    }
    
    div {
      font-size: 20px;
    }
    
    .right {
      display: flex;
      flex-direction: column;
      align-items: center;           
    }
       
    .right small {
      margin-top: .5em;
      font-size: 0.9em;
    }
  `}>
    <ContentMargins className={"content"}>
      <div className={"left"}>
        <h2>
          {props.prompt}
        </h2>
        <div>
          {props.promptSubtitle}
        </div>
      </div>

      <div className={"right"}>
        <Button to={"/kontakt"}>Kontakt</Button>
        <small>
          <span style={{fontSize: '.9em'}}>Direkt:</span> {phoneNumber}
        </small>
      </div>
    </ContentMargins>
  </div>
}
RequestConsultationBlock.defaultProps = {
  prompt: 'Vereinbaren Sie jetzt einen Beratungstermin.',
  promptSubtitle: 'Gerne kommen wir bei Ihnen vorbei.',
}



export function ContactOrShopBlock(props: {
  promptContact: string,
  promptOnline: string,
}) {
  return  <div css={css`    
    background: #ececec;
    margin: 40px 0;
    
    .content {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      padding: 2.5em 1em;
      
      // TO MAKE IT AN INLINE BOX:
      // border: 1px solid silver;
      // border-radius: 4px;
      background: #ececec;
    }
    
    .left {
      text-align: center;
    }
    
    h2 {
      font-size: 30px;
      margin: 0;
    }
    
    div {
      font-size: 20px;
    }
    
    .right {
      display: flex;
      flex-direction: column;
      align-items: center; 
      margin-top: 20px;         
    }
       
    .right small {
      margin-top: .5em;
      font-size: 0.9em;
    }
  `}>
    <ContentMargins className={"content"}>
      <div className={"left"}>
        <h2>
          {props.promptContact}
        </h2>

        <div className={"right"}>
          <Button to={"/kontakt"}>Kontakt</Button>
        </div>
      </div>

      <div className={"left"}>
        <h2>
          {props.promptOnline}
        </h2>

        <div className={"right"}>
          <Button href={`http://shop.blumen-elsdoerfer.de`}>Zum Online-Shop</Button>
        </div>
      </div>
    </ContentMargins>
  </div>
}
ContactOrShopBlock.defaultProps = {
  promptContact: 'Lassen Sie sich beraten.',
  promptOnline: 'Bestellen Sie Online.'
}


function usePhoneNumber() {
  const data = useStaticQuery(graphql`
    query UsePhoneNumberQuery {
      sanityCompanyProfile(_id: {eq: "blumen-elsdoerfer"}) {
        phoneNumber
      }
    }
  `);
  return data.sanityCompanyProfile.phoneNumber;
}