import * as React from 'react'
import ContentMargins from './ContentMargins';
import BlockContent from "@sanity/block-content-to-react";
import {Link} from 'gatsby';
import {css} from "@emotion/core";


const singleStyle = css`
  float: right; 
  // Ensure we show them below each other.
  clear: right;
`;

const style = css`  
  padding: 20px;
  max-width: 200px;
  background-color: #9b1030;
  color: white;
  border-radius: 5px;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: center;
  
  min-width: 200px;
  
  text-decoration: none;
   
  
  & .text {  
    font-size: 20px;
  }
  
  & .icon {
    fill: white;
    font-size: 1.7em;
    float: left;
    margin-right: 0.1em;
    margin-bottom: 0.1em;
    margin-top: -0.4em;
    margin-left: -0.4em;
  }
  
  & strong {
    margin-bottom: 1em;
    display: block;
  }
  
  & p:first-of-type {
    margin-top: 0;
  }
  & p:last-of-type {
    margin-bottom: 0;
  }
  
  &:hover {
    opacity: .8;
  }
`;


const InfoIcon = (props: any) => (
  <svg width="1em" height="1em" viewBox="0 0 26 26" {...props}>
    <path d="M13 1.188C6.477 1.188 1.187 6.476 1.187 13S6.478 24.813 13 24.813c6.523 0 11.813-5.29 11.813-11.813 0-6.523-5.29-11.813-11.813-11.813zm2.46 18.308c-.608.238-1.093.422-1.456.547a3.864 3.864 0 01-1.262.187c-.734 0-1.308-.18-1.719-.539-.406-.355-.609-.812-.609-1.367 0-.215.016-.433.047-.656.027-.227.078-.477.144-.762l.762-2.687c.067-.258.125-.5.172-.73.047-.231.066-.442.066-.634 0-.34-.07-.582-.21-.714-.145-.137-.415-.204-.813-.204a2.23 2.23 0 00-.605.09 9.443 9.443 0 00-.532.176l.203-.828c.497-.203.973-.375 1.43-.52a4.27 4.27 0 011.29-.218c.73 0 1.296.18 1.69.531.395.352.594.812.594 1.375 0 .117-.011.324-.039.617a4.225 4.225 0 01-.152.813l-.758 2.68c-.062.214-.117.46-.168.734a4.05 4.05 0 00-.07.625c0 .355.078.601.238.73.156.13.434.192.828.192.184 0 .39-.032.625-.094a3.59 3.59 0 00.504-.172zm-.136-10.879a1.802 1.802 0 01-1.273.492c-.496 0-.926-.164-1.281-.492a1.563 1.563 0 01-.532-1.191c0-.465.18-.867.532-1.2a1.813 1.813 0 011.28-.496c.497 0 .923.165 1.274.497.356.332.531.734.531 1.199 0 .465-.175.863-.53 1.191z" />
  </svg>
);


export function RefToKeypointBox(props: {
  keyPoint: {
    title: string,
    shortText: string,
    slug: {
      current: string
    }
  }
}) {
  return <ContentMargins>
    <KeypointBox keyPoint={props.keyPoint} css={singleStyle} />
  </ContentMargins>
}



export function KeypointBox(props: {
  keyPoint: {
    title: string,
    shortText: string,
    slug: {
      current: string
    }
  }
  className?: string
}) {
  return <Link to={`/${props.keyPoint.slug.current}`} css={style} className={props.className}>
    <div className={"text"}>
      <InfoIcon className={"icon"} />
      {/*<BlockContent*/}
      {/*  renderContainerOnSingleChild={false}*/}
      {/*  blocks={props.keyPoint.shortText}*/}
      {/*/>*/}
      {props.keyPoint.title}
    </div>
  </Link>
}
